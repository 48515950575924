import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { User } from '@app/models/user';
import { AuthService } from '@app/services/user/auth.service';
import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, filter } from 'rxjs';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent implements OnInit {

  @Input() sidebarId: string = "sidebar";

  visibleNavbar: boolean = false;
  name: string = "";
  user!: User;

  role_items: any = [
    {
      id: 1,
      name: "Administrador",
      url: "/theme/colors",
      base_name: "administrador"
    },
    {
      id: 2,
      name: "Operaciones",
      url: "/operaciones/diarias",
      base_name: "operaciones"
    },
    {
      id:3 ,
      name: "Corfid",
      url: "/corfid/clientes-pendientes",
      base_name: "corfid"
    },
    {
      id: 4,
      name: "CRM",
      url: "/forms/form-control",
      base_name: "proveedor"
    },
    {
      id: 5,
      name: "Proveedor de Liquidez",
      url: "/proveedor/dashboard",
      base_name: "proveedor"
    },
    {
      id: 6,
      name: "Ejecutivos",
      url: "/ejecutivos/dashboard",
      base_name: "ejecutivos"
    },
    {
      id: 7,
      name: "Supervisor",
      url:"/supervisores/dashboard",
      base_name: "supervisor"
    }
  ]

  current_roles = Object.values(JSON.parse(localStorage.getItem("roles")!) || []);

  faUser = faUserCircle;

  constructor(private authService: AuthService, private classToggler: ClassToggleService, private router: Router) {
    super();
  }

  ngOnInit(): void { 
    //Set User
    this.authService.getUser.subscribe(response => {
      this.user = response;
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() =>{
        this.setNameBasedOnUrlOrUserRole();
    });
    this.setNameBasedOnUrlOrUserRole();
  }

  setNameBasedOnUrlOrUserRole(): void {
    let roleFromUrl = this.router.url.split("/")[1];
    if(roleFromUrl){
      roleFromUrl = roleFromUrl.charAt(0).toUpperCase() + roleFromUrl.slice(1).toLowerCase();
      this.name = roleFromUrl;
    }else if(this.user){
      this.name = this.role_items.find((item: any) => item.base_name === this.user.role?.name)?.name;    
    }
  }

  toggleNavbar(): void {
    this.visibleNavbar = !this.visibleNavbar;
  }

  changeRole(name: string): void {
    this.name = name;
  }

  logout(): void {
    this.authService.logout().subscribe(response => {
      if(response.success) {
        this.authService.setUser = {
          id: 0,
          name: '',
          last_name: '',
          email: '',
          phone: '',
          role: {
            id: 0,
            name: '',
            guard_name: '',
            created_at: '',
            updated_at: ''
          }
        };
        localStorage.removeItem("vendorSelectedId");
        this.router.navigate(["login"])
      }
    });
  }
}
