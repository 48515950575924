<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true" class="d-flex justify-content-between">
    <button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1 d-flex align-items-center"
    >
    <p class="my-0 d-flex d-md-none mx-3">BILLEX</p>
      <svg cIcon
        name="cilMenu"
        size="lg"
      ></svg>
    </button>


    
    <c-header-nav class="ms-3 d-flex align-items-center">
      <!--<button
        toggle="visible"
        cHeaderToggler
        [cSidebarToggle]="navId"
        class="ps-1 d-flex d-md-none align-items-center"
      >
        <svg cIcon
          name="cilMenu"
          size="lg"
        ></svg>
      </button>-->
      <button class="navbar-toggler" type="button" style="border: 0; background: none" data-toggle="collapse" data-target="#layout-navbar-collapse" (click)="toggleNavbar()" class="d-md-none">
        <span class="navbar-toggle-icon">
          <svg cIcon
        name="cilMenu"
        size="lg"
      ></svg>
        </span>
      </button>
      <div class="d-flex align-items-center d-none d-md-flex">
        <ng-container *ngTemplateOutlet="rolDropDown"></ng-container>
        <div style="height: 20px; width: 2px; border-right: 1px solid rgba(24,28,33,0.2)" class="mx-2"></div>
        <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
      </div>
    </c-header-nav>
    
  </c-container>
  <div class="navbar-collapse collapse d-md-none" [ngClass]="visibleNavbar ? 'd-block': 'd-none'" id="layout-navbar-collapse">
    <div class="navbar-nav d-flex flex-column align-items-end">
      <div class="my-2">
        <ng-container *ngTemplateOutlet="rolDropDown"></ng-container>
      </div>
      <div class="my-2">
        <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
      </div>
    </div>

  </div>
  <!--<c-container class="d-flex d-md-flex align-items-center">
    <div class="d-flex align-items-center d-none d-md-flex">
      <ng-container *ngTemplateOutlet="rolDropDown"></ng-container>
      <div style="height: 20px; width: 2px; border-right: 1px solid rgba(24,28,33,0.2)" class="mx-2"></div>
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </div>
  </c-container>

</ng-container>-->
<!--</c-header>-->

<ng-template #rolDropDown>
  <c-dropdown alignment="end" variant="nav-item">
    <button
      cButton
      color=""
      [caret]="true"
      cDropdownToggle
      class="py-0 d-flex align-items-center"
      style="border: none;"
    >
    <div class="d-flex align-items-center">
      <p class="my-0 mx-2">{{name}}</p>
    </div>
    </button>
    <ul cDropdownMenu>

      <li *ngIf="current_roles.indexOf('operaciones') > -1 " 
        [routerLink]="['/operaciones/diarias']"
        onMouseOver="this.style.background='rgba(24,28,33,0.05)'"
        onMouseOut="this.style.background='#fff'" 
        style="cursor: pointer;"
        cDropdownItem
        (click)="changeRole('Operaciones')"
        [disabled]="name==='Operaciones'"
        >Operaciones</li>
      <li *ngIf="current_roles.indexOf('ejecutivos') > -1 " 
        [routerLink]="['/ejecutivos']"
        onMouseOver="this.style.background='rgba(24,28,33,0.05)'"
        onMouseOut="this.style.background='#fff'"
        style="cursor: pointer;" 
        cDropdownItem
        (click)="changeRole('Ejecutivos')"
        [disabled]="name==='Ejecutivos'"
        >Ejecutivos</li>
      <li *ngIf="current_roles.indexOf('supervisores') > -1 " 
        [routerLink]="['/supervisores']"
        onMouseOver="this.style.background='rgba(24,28,33,0.05)'"
        onMouseOut="this.style.background='#fff'"
        style="cursor: pointer;" 
        cDropdownItem
        (click)="changeRole('Supervisores')"
        [disabled]="name==='Supervisores'"
        >Supervisores</li>
      <li *ngIf="current_roles.indexOf('administrador') > -1 " 
        [routerLink]="['/administrador']"
        onMouseOver="this.style.background='rgba(24,28,33,0.05)'"
        onMouseOut="this.style.background='#fff'"
        style="cursor: pointer;"
        cDropdownItem
        (click)="changeRole('Administrador')"
        [disabled]="name==='Administrador'"
        >Administrador</li>
      <li *ngIf="current_roles.indexOf('corfid') > -1 " 
        [routerLink]="['/corfid']"
        onMouseOver="this.style.background='rgba(24,28,33,0.05)'"
        onMouseOut="this.style.background='#fff'"
        style="cursor: pointer;"
        cDropdownItem
        (click)="changeRole('Corfid')"
        [disabled]="name==='Corfid'"
        >Corfid</li>
      <li *ngIf="current_roles.indexOf('proveedor') > -1 " 
        [routerLink]="['/proveedor/dashboard']"
        onMouseOver="this.style.background='rgba(24,28,33,0.05)'"
        onMouseOut="this.style.background='#fff'"
        style="cursor: pointer;"
        cDropdownItem
        (click)="changeRole('Proveedor')"
        [disabled]="name==='Proveedor'"
        >Proveedor</li>
      <!--<li *ngFor="let item of role_item_aux">
        <a class="py-2" 
        onMouseOver="this.style.background='rgba(24,28,33,0.05)'" 
        onMouseOut="this.style.background='#fff'" 
         cDropdownItem (click)="changeRole(item.name, item.id)"
         [routerLink]="item.url">
          {{item.name}}
        </a>
      </li>-->
      
    </ul>
  </c-dropdown>
</ng-template>
<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button
      cButton
      color=""
      [caret]="true"
      cDropdownToggle
      class="py-0 d-flex align-items-center"
      style="border: none;"
    >
    <div class="d-flex align-items-center">
      <fa-icon [icon]="faUser"></fa-icon>
      <p class="my-0 mx-2">{{ user?.name }} {{ user?.last_name }}</p>
    </div>
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto mt-3">

      <li>
        <a class="py-2" 
        onMouseOver="this.style.background='rgba(24,28,33,0.05)'" 
        onMouseOut="this.style.background='#fff'" 
        routerLink="./" cDropdownItem>
          Mi Perfil
        </a>
      </li>
      <li>
        <a class="py-2" 
        onMouseOver="this.style.background='rgba(24,28,33,0.05)'" 
        onMouseOut="this.style.background='#fff'" 
        routerLink="./" cDropdownItem>
          Crear Alerta de tipo de cambio
        </a>
      </li>
      <li>
        <a class="py-2" 
        onMouseOver="this.style.background='rgba(24,28,33,0.05)'" 
        onMouseOut="this.style.background='#fff'" 
        routerLink="./" cDropdownItem>
          Cambiar contraseña
        </a>
      </li>
      <li class="d-flex align-items-center px-3 mx-0">
        <svg cIcon
        name="cilExitToApp"
        size="lg"
        ></svg>
        <a class="py-2 px-2" 
        onMouseOver="this.style.background='rgba(24,28,33,0.05)'" 
        onMouseOut="this.style.background='#fff'" 
        cDropdownItem
        (click)="logout()">
          Salir
        </a>
      </li>
      
    </ul>
  </c-dropdown>
</ng-template>

